@media screen and (max-width: 667px) {
    .glass-panel-container {
        flex: 1; 
        width: 100%;
    }
}

@media screen and (min-width: 668px) {
    .glass-panel-container {
        height: calc(100vh - 128px);  
        width: 50%;
    }
}

.glass-panel-container {
    align-items: center;
    backdrop-filter: blur( 20px );
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    cursor: pointer;
    display: flex;
    justify-content: center;
    text-align: center;
    transition: 0.4s;  

    a {
        align-items: center;
        border-radius: 24px;
        display: flex;
        height: 100%;
        justify-content: center;
        width: 100%;
    }

    h2 {
        color: #FFFFFF;
        font-size: 20px;
        padding: 30px;
        text-shadow: 2px 2px 16px rgba(54,54,54,0.5);
    }
}

@media(hover: hover) and (pointer: fine) {
    .glass-panel-container:hover {
        background: rgba( 255, 255, 255, 0.33 );
        text-shadow: none;
        transform: scale(0.99);
        transition: 0.4s;
    }
}