@media screen and (max-width: 367px) {
    .secondary-btn-container {
        border-radius: 44px;
        height: 30px;
        width: 30px;

        h4 {
            display: none;
        }

        img {
            width: 8px;
            padding-right: 3px;
        }
    }
}

@media screen and (min-width: 368px) and (max-width: 567px) {
    .secondary-btn-container {
        border-radius: 44px;
        height: 44px;
        width: 44px;

        h4 {
            display: none;
        }

        img {
            padding-right: 3px;
            width: 8px;
        }
    }
}

@media screen and (min-width: 568px) {
    .secondary-btn-container {
        border: 2px solid #333333;
        border-radius: 14px;
        height: 44px;
        width: 120px;

        img {
            display: none;
        }
    }
}

.secondary-btn-container {
    align-items: center;
    backdrop-filter: blur( 8px );
    background: rgba( 255, 255, 255, 0.1 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    -webkit-backdrop-filter: blur( 8px );
    border-radius: 10px;
    border: 2px solid rgba( 255, 255, 255, 0.18 );
    cursor: pointer;
    display: flex;
    justify-content: center;
    transition: 0.4s;
}

@media(hover: hover) and (pointer: fine) {
    .secondary-btn-container:hover {
        background: rgba( 255, 255, 255, 0.375 );
        transform: scale(0.99);
        transition: 0.4s;
    }
}