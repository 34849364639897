body {
  box-sizing: border-box;
  color: #FFFFFF;
  margin: 0;
  font-family: 'Roboto Condensed';
}

a {
  text-decoration: none;
}

h1,h2,h3,h4 {
  font-weight: 600;
  margin: 0;
}

h1 {
  font-size: 48px;
}

h3 {
  font-size: 20px;
  line-height: 28px;
}