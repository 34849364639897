@media screen and (max-width: 667px) {
    .frame-row-container {
        flex-direction: column;
        height: calc(100vh - 120px); 
    }
}

.frame-row-container {
    display: flex;
    gap: 20px;
    justify-content: space-around;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
}
