@media screen and (max-width: 367px) {
    .misc-experiences-container {
        .page-header-container {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;

            .title {
                h1 {
                    font-size: 48px;
                    padding: 20px;
                }
            }
        }

        .videos-container {
            gap: 50px;
            margin-bottom: 100px;
            margin-top: 50px;
        }
    }
}

@media screen and (min-width: 368px) and (max-width: 567px) {
    .misc-experiences-container {
        .page-header-container {
            flex-direction: column;
            margin-top: 50px;
            text-align: center;

            .title {
                h1 {
                    font-size: 60px;
                    padding: 20px;
                }
            }
        }

        .videos-container {
            gap: 50px;
            margin-bottom: 100px;
            margin-top: 50px;
        }
    }
}

@media screen and (min-width: 568px) and (max-width: 967px) {
    .misc-experiences-container {
        .page-header-container {
            flex-direction: column;
            margin-left: 20px;
            margin-top: 50px;
            text-align: center;

            .title {
                h1 {
                    font-size: 72px;
                    padding: 40px;
                }
            }

            .description {
                margin-left: 50px;
                margin-right: 50px;
            }
        }

        .videos-container {
            gap: 50px;
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
}


@media screen and (min-width: 968px) and (max-width: 1079px) {
    .misc-experiences-container {
        .page-header-container {
            gap: 40px;
            margin-left: 20px;
            margin-top: 50px;

            .title {
                h1 {
                    font-size: 72px;
                }
            }
        }

        .videos-container {
            gap: 50px;
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
}

@media screen and (min-width: 1080px) {
    .misc-experiences-container {
        .page-header-container {
            gap: 40px;
            margin-left: 20px;
            margin-top: 50px;

            .title {
                h1 {
                    font-size: 88px;
                }
            }
        }

        .videos-container {
            gap: 50px;
            margin-bottom: 50px;
            margin-top: 50px;
        }
    }
}

.misc-experiences-container {
    .page-header-container {
        align-items: center;
        display: flex;
        height: fit-content;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 1280px;

        .title {
            padding-left: 30px;

            h1 {
                color: #FFFFFF;
                text-shadow: 4px 4px #3636365b;
                width: fit-content;
            }
        }

        .description {
            align-items: center;
            display: flex;
            flex-grow: 1;
            height: fit-content;
            justify-content: center;
            width: fit-content;

            backdrop-filter: blur( 48px );
            background: rgba(31, 31, 31, 0.3);
            box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
            -webkit-backdrop-filter: blur( 8px );
            border-radius: 10px;
            border: 1px solid rgba( 255, 255, 255, 0.18 );
            padding: 20px;

            p {
                font-size: 20px;
                line-height: 30px;
                text-align: center;
            }
        }
    }

    .videos-container {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        iframe {
            height: 400px;
            max-width: 800px;
            width: 80%;
        }
    }
}