@media screen and (max-width: 567px) {
    .about-container {
        min-height: 100vh;
        padding: 20px;

        .about-content {
            display: flex;
            flex-direction: column;
            margin-top: 200px;

            .text-panel-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                .text-panel {
                    backdrop-filter: blur( 20px );
                    background: rgba(31, 31, 31, 0.1);
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    -webkit-backdrop-filter: blur( 8px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    padding: 20px;
                }
            }
        }

        h2,h3,p {
            text-shadow: 2px 2px 16px rgba(54,54,54,0.5);
        }

        h2 {
            font-size: 40px;
        }

        h3 {
            font-style: italic;
            font-weight: 300;
        }

    }
}

@media screen and (min-width: 568px) and (max-width: 767px) {
    .about-container {
        min-height: 100vh;
        padding: 20px;

        .about-content {
            display: flex;
            flex-direction: column;
            margin-top: 200px;

            .text-panel-container {
                display: flex;
                flex-direction: column;
                gap: 20px;
                width: 100%;

                .text-panel {
                    backdrop-filter: blur( 20px );
                    background: rgba(31, 31, 31, 0.1);
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    -webkit-backdrop-filter: blur( 8px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    padding: 20px;
                }
            }
        }

        h2,h3,p {
            text-shadow: 2px 2px 16px rgba(54,54,54,0.5);
        }

        h2 {
            font-size: 40px;
        }

        h3 {
            font-style: italic;
            font-weight: 300;
        }
    }
}

@media screen and (min-width: 768px) {
    .about-container {
        padding: 20px;
        min-height: 100vh;

        .about-content {
            display: flex;
            flex-direction: column;
            margin-top: 200px;

            .text-panel-container {
                display: flex;
                gap: 20px;
                width: 100%;

                .text-panel {
                    backdrop-filter: blur( 20px );
                    background: rgba(31, 31, 31, 0.1);
                    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
                    -webkit-backdrop-filter: blur( 8px );
                    border-radius: 10px;
                    border: 1px solid rgba( 255, 255, 255, 0.18 );
                    display: flex;
                    flex-direction: column;
                    flex-grow: 1;
                    padding: 20px;
                }
            }
        }

        h2,h3,p {
            text-shadow: 2px 2px 16px rgba(54,54,54,0.5);
        }

        h2 {
            font-size: 40px;
        }

        h3 {
            font-style: italic;
            font-weight: 300;
        }

        p {
            max-width: 480px;
        }
    }
}